import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import { Project } from "shared/types/project"
import { generateClient } from "aws-amplify/api"
import { getProjects } from "redux/slices/projects"
import { listDocumentsByCountryId } from "graphql/queries"
import useBooleanState from "hooksV1/useBooleanStates"
import SideNaveV1 from "components/SideNav/SideNaveV1"
import DocumentsToolBar from "components/DocumentsToolBar"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import { fetchProjectsByUserV2 } from "redux/thunks/projectsThunk"
import { useGetDocumentVersionAccessRightsV1Query } from "redux/services"
import { containsPath, getUpdatedRoles, sortDocumentsOrder } from "util/helper"
import ManageDocumentsToolBar from "components/ManageDocumentsToolBar/ManageDocumentsToolBar"
import { AuthHelper } from "util/authHelper"

const client = generateClient()

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    flexGrow: 1,
    padding: "0.75rem",
  },
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "0.5rem",
  },
  sideNav: {
    width: "12.5%",
    height: "100%",
  },
  body: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
}))

const ProjectPageV1: React.FC = () => {
  const classes = useStyles()
  let navigate = useNavigate()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const location = useLocation()
  const booleanState = useBooleanState()

  const {
    activeCountry,
    activeProject,
    activeDocument,
    activeProjectUser,
    activeDocumentVersion,
    setDocuments,
    setDocumentRoles,
    setActiveProject,
    setActiveDocument,
    setActiveProjectUser,
    setActiveDocumentVersion,
  } = useAppState()

  const projects: Project[] = useSelector(getProjects)

  const [docAccess, setDocAccess] = useState([])
  const [currentPath, setCurrentPath] = useState<string[]>([])

  const pathArray = location.pathname.split("/")
  const projectId = pathArray[2]
  const activeDocId = pathArray[4]
  const activeCountryName = pathArray[6]?.replace(/%20/g, " ")

  const { data: documentVersionData, isLoading } =
    useGetDocumentVersionAccessRightsV1Query(
      {
        documentId: activeDocument?.id,
        id: activeDocumentVersion?.id,
      },
      { skip: !activeDocument || !activeDocumentVersion }
    )

  useEffect(() => {
    if (!documentVersionData) return

    const access = getUpdatedRoles(
      documentVersionData.data?.getDocumentVersion?.access,
      documentVersionData.data?.getDocumentVersion?.access
    )

    setDocAccess(access)
    setDocumentRoles(access)
  }, [documentVersionData])

  useEffect(() => {
    if (projects.length > 0) {
      const activeProject = projects.find((project) => project.id === projectId)
      setActiveProject(activeProject)
      const activeUser = activeProject?.users.find(
        (user) => user.user.id === sessionStorage.getItem("id")
      )
      setActiveProjectUser(activeUser)
    }
  }, [projects])

  useEffect(() => {
    localStorage.setItem("chapterIndex", "1.")

    if (projects.length === 0) {
      onFetchProjects()
    }
  }, [])

  useEffect(() => {
    if (!activeCountry || !activeProject || !hasCountryOrDocumentChanged())
      return

    const fetchCountryData = async () => {
      try {
        booleanState.setIsDocumentsLoading(true)
        booleanState.setIsDocumentVersionLoading(true)

        const result = await client.graphql({
          query: listDocumentsByCountryId,
          variables: {
            filter: {
              countryId: {
                eq: activeCountry?.id,
              },
              enabled: {
                eq: true,
              },
            },
          },
        })

        let documents: any[] = result?.data?.listDocuments?.items ?? []

        documents = sortDocumentsOrder(
          documents?.filter((document) =>
            AuthHelper.canViewDocument(
              activeCountry?.id,
              document.documentVersions["items"][0].access,
              activeProjectUser
            )
          )
        )

        const newActiveDoc = documents.find((doc) => doc.id === activeDocId)

        if (newActiveDoc) {
          setDocuments(documents)
          setActiveDocument(newActiveDoc)
          let documentVersions = newActiveDoc?.documentVersions["items"][0]
          setActiveDocumentVersion(documentVersions)
        } else {
          const activeDocumentViaRefId = documents.find(
            (doc) => doc.refId === activeDocument?.refId
          )

          setDocuments(documents)
          setActiveDocument(activeDocumentViaRefId)
          let documentVersions = newActiveDoc?.documentVersions["items"][0]
          setActiveDocumentVersion(documentVersions)

          const currentPath = pathArray[pathArray.length - 1]

          if (!activeDocumentViaRefId) {
            navigate(`/project/${projectId}}/country/${activeCountryName}`)
          } else if (containsPath(currentPath)) {
            navigate(
              `/project/${projectId}/document/${activeDocumentViaRefId.id}/country/${activeCountryName}/${currentPath}`
            )
          } else {
            navigate(
              `/project/${projectId}/document/${activeDocumentViaRefId.id}/country/${activeCountryName}`
            )
          }
        }
      } catch (error) {
        logger("ProjectDocumentV1", "useEffect (fetchCountryData)", error)

        snackBar.setMessage(
          "An error occurred fetching the countries information. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setCurrentPath(pathArray)
        booleanState.setIsDocumentsLoading(false)
        booleanState.setIsDocumentVersionLoading(false)
      }
    }

    fetchCountryData()
  }, [activeCountry, activeProject, location])

  useEffect(() => {
    booleanState.setIsDocumentVersionLoading(isLoading)
  }, [isLoading])

  const onFetchProjects = () => {
    let fetchProjThunk = fetchProjectsByUserV2(sessionStorage.getItem("id"))
    dispatch(fetchProjThunk)
  }

  const hasCountryOrDocumentChanged = (): boolean => {
    const pathArray = location.pathname.split("/")

    return (
      pathArray[2] !== currentPath[2] ||
      pathArray[4] !== currentPath[4] ||
      pathArray[6] !== currentPath[6]
    )
  }

  return (
    <main className={classes.main}>
      <div className={classes.root}>
        <DocumentsToolBar />
        <div className={classes.container}>
          <div className={classes.sideNav}>
            <SideNaveV1 isProjectDocuments documentAccess={docAccess} />
          </div>
          <div className={classes.body}>
            <div
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              <ManageDocumentsToolBar />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  )
}

export default ProjectPageV1
