import { create } from "zustand"
import { Document } from "shared/types-exp"

type SyncDialogState = {
  isOpen: boolean
  documents: Document[]
}

type SyncDialogActions = {
  onOpen: () => void
  onClose: () => void
  setDocuments: (documents: Document[]) => void
}

const useSyncDocumentsFailedDialogV1 = create<
  SyncDialogState & SyncDialogActions
>((set) => ({
  isOpen: false,
  documents: [],
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setDocuments: (documents) => set({ documents }),
}))

export default useSyncDocumentsFailedDialogV1
