import {
  ListItem,
  ListItemText,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
import useSyncDocumentsFailedDialogV1 from "hooksV1/useSyncDocumentsFailedDialogV1"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "30vw",
    maxHeight: "60vh",
    display: "flex",
    flexDirection: "column",
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  listItem: {
    padding: "1em",
    borderBottom: "1px solid rgba(0,0,0,0.15)",
  },
  listItemText: {
    display: "inline-block",
  },
  typographyInline: {
    display: "inline",
  },
}))

const SyncDocumentsFailedDialog: React.FC = () => {
  const classes = useStyles()

  const syncDocDialog = useSyncDocumentsFailedDialogV1()

  const handleClose = () => {
    syncDocDialog.onClose()
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") syncDocDialog.onClose()
  }

  return (
    <Dialog
      open={syncDocDialog.isOpen}
      onClose={handleClose}
      maxWidth="lg"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Template Documents Not Synced</DialogTitle>
      <DialogContent className={classes.container} dividers>
        {syncDocDialog.documents.map((doc) => {
          return (
            <ListItem className={classes.listItem} key={doc.id}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <>
                    {doc.name}
                    &nbsp;
                    <Typography
                      className={classes.typographyInline}
                      color="error"
                    >
                      (Failed)
                    </Typography>
                  </>
                }
              />
            </ListItem>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SyncDocumentsFailedDialog
